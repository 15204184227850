.vacancy-card {
  cursor: pointer;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  padding: 16px;
  background-color: #f8f8f8;
  max-width: 353px;
}

.vacancy-card__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.vacancy-card__header-left {
  display: flex;
  align-items: center;
}

.vacancy-card__logo {
  border-radius: 20px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.vacancy-card__department {
  color: #888;
  font-size: 12px;
}

.vacancy-card__description {
  font-size: 12px;
  color: #000;
  margin-bottom: 24px;
  line-height: 100%;
}

.vacancy-card__title {
  font-weight: 700;
  font-size: 16px;
  color: #000;
  margin: 16px 0 12px 0;
  line-height: 100%;
}

.vacancy-card__type {
  font-size: 12px;
  color: #888;
}

.vacancy-card__date {
  font-size: 12px;
  color: #000;
}

.vacancy-card__icons {
  display: flex;
  align-items: center;
  gap: 7px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.vacancy-card__options-btn, .vacancy-card__favorite-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.vacancy-card:hover .vacancy-card__icons {
  visibility: visible;
  opacity: 1;
}