/* General Styles */
.main {
    padding-top: 28px;
    padding-bottom: 116px;
}

/* Information Section */
.information-section {
    margin: 24px auto;
    padding: 0 36px;
}

.information-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.info-card {
    width: auto;
    padding: 24px;
    flex: 1;
    background: #F8F8F8;
    border-radius: 4px;
    border: 1px solid #C7C7C7;
    margin-right: 24px;
}

.info-card:hover {
    transform: scale(1.05);
}

.info-card h2 {
    margin-bottom: 10px;
}

.info-card p {
    color: #666;
}

/* Questions Section */
.questions-section {
    margin: 0 auto 58px auto;
    padding: 36px;
}

.question-main {
    width: auto;
    padding: 24px;
    margin: 0 auto 24px auto;
    border-radius: 4px;
    border: 1px solid #C7C7C7;
    position: relative;
    cursor: pointer;
}

.question-main::after {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url('../../img/icon/Vector.svg');
    background-repeat: no-repeat;
    position: absolute;
    right: 26.1px;
    top: 26.1px;
    transition: transform 0.3s;
}

.question-main.open::after {
    transform: rotate(360deg);
    background-image: url('../../img/icon/uiw_down.svg');
}


.question-text {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.42px;
    text-align: left;
    color: #666;
    display: none;
}

.question-text.visible {
    display: block;
}

.question-text.hidden {
    display: none;
}

.Footer {
    margin-top: 183px;
}
