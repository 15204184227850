.project-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 4px;
  /* overflow: hidden; */
  position: relative;
}

.project-image-container {
  overflow: hidden; 
  margin-bottom: 12px;
  object-fit: cover;
}

.project-image {
  width: 100%;
  height: 244px;
  transition: transform 0.3s ease; 

  &:hover {
    transform: scale(1.1);
  }
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
 
.more-options {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

}

.project-title {
  font-weight: 700;
  font-size: 16px;
  color: #000;
}

.project-author {
  color: #555;
  margin-bottom: 14px;
}

.project-stats {
  display: flex;
  gap: 8px;
}

.project-date {
  font-size: 12px;
  color: #000;
}

.project-stats span{
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
}

.context-menu {
  position: absolute;
  bottom: -62%;
  right: 0%;
  padding: 16px;
  max-width: 184px;
  width: 100%;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.context-menu ul {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.context-menu li {
  /* padding: 5px 10px; */
  cursor: pointer;
  font-size: 16px;
}
