.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: center;
  position: relative;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.modalClose {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

h1 {
  color: #4A90E2;
  font-size: 24px;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
}

.modalContent p:last-child {
  font-weight: bold;
  color: #333;
  margin-top: 20px;
}
