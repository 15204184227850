.searchSection {
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-top: 54px;
    gap: 20px; 
}

.searchBar, .searchBlue, .contactButton {
    display: flex;
    align-items: center;
    padding: 16px 28px;
    border-radius: 51px;
    margin-right: 20px;
    font-size: 16px;
    color: #fff;
    height: 44px; 
}

.searchBar {
    width: 800px;
    border: 1px solid #ddd;
    background-color: #efefef;
}

.searchBlue, .contactButton {
    width: 248px;
    border: 1px solid #ddd;
}

.searchBlue {
    background-color: #40A6FF;
    border-color: #40A6FF;
    color: #fff;
}

.contactButton {
    background-color: #EFEFEF;
    border-color: #DDD;
}
