.banner {
  position: relative;
  height: 200px;
}

.banner__image {
  background-image: url('../../img/banner_home.jpg');
  background-size: cover;
  background-position: center;
  height: 100%;
  filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.banner__overlay {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.banner__text {
  color: #000;
	font-weight: 500;
	font-size: 64px;	
}
