.sort {
	position: absolute;
	top: 50px;
	right: 0;
	z-index: 3;
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 16px;
	box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.25);
	border-radius: 24px;
  background-color: #f8f8f8;
	display: none;
}

.sort-button {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 51px;
  padding: 16px 28px;
  width: 248px;
  height: 44px;
  background-color: #efefef;
  font-size: 16px;
  color: #000;
  text-align: left;
  cursor: pointer;
}

.sort.visible {
	display: block;
}

.sort h2 {
	font-weight: 700;
	font-size: 16px;
}

.sort-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.sort-header button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.hidden-radio {
  display: none;
}