.parent-container {
    position: relative;
    width: 100%;
    height: auto;
}

.banner-container {
    position: relative;
}

.filter-education, .search-education {
    position: relative;
    padding: 16px 28px;
    z-index: 1;
}

.filter-education button, .blue-search, .sort-search {
    width: 248px;
    height: 44px;
    border-radius: 51px;
    border: 2px solid transparent;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.42px;
    cursor: pointer;
}

.filter-education button {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    bottom: 35px;
    z-index: 1;
    margin-right: 16px;
}

.filter-education button:hover {
    border: 2px solid #0D0D0D;
    transition: all .3s linear;
}

.search-education input {
    width: 523px;
    height: 44px;
    padding: 16px 28px;
    border-radius: 51px;
    border: 1px solid #DDDDDD;
    background-color: #EFEFEF;
    margin-right: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.42px;
    text-align: left;
}

.blue-search {
    background-color: #40A6FF; 
    color: #fff;   
}

.sort-search {
    float: right;
}

.content-wrapper {
    display: flex;
    align-items: flex-start; 
    padding: 16px 28px;
    gap: 16px; 
}

.results-count {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0; 
    padding: 0; 
}

.articles-section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px; 
    padding: 0; 
    margin-top: 32px; 
}

.article-item {
    display: flex;
    flex-direction: column;
    width: 353px;
    height: auto; 
    margin-bottom: 16px; 
}

.logo {
    width: 353px;
    height: 244px;
    border-radius: 4px;
}

.title-and-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
}

.title-and-icon h3 {
    margin-right: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description {
    margin-bottom: 4px; 
}

.articles-section p, span {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.86px;
    text-align: left;
}

.ancillary-icon {
    width: 24px; 
    height: 24px;
}

.article-info img, span {
    margin-right: 4px;    
}

.days {
    margin-left: 16px;    
}

.ancillary-icon {
    display: flex;
    margin-left: 8px;    
}

.article-item .description {
    margin-top: 10px; 
}
  
.article-item p.courses {
    margin-top: 5px; 
}

.article-info {
    margin-top: 10px;
}
