.support-container {
    display: flex;
    justify-content: center;
    align-items: center; 
    min-height: 100vh;
    padding: 24px;
}

.support-main {
    width: 812px; 
    height: Hug (1,103px); 
    padding: 24px;
    border-radius: 4px;
    border: 1px solid #C7C7C7; 
}

.support-main h2 {
    margin-top: 24px;
}

.support-main h3 {
    margin-bottom: 24px;
}

.email, .subject, .question-support,  .description, .add-file {
    margin-top: 48px;
}

.email input, .subject input, .question-support button, .submit button, .add-file button, .description input {
    width: 100%; 
    height: 44px; 
    padding: 16px 28px;
    border-radius: 51px;
    border: 1px solid #DDDDDD;
    background-color: #EFEFEF;
    font-weight: 500; 
    justify-content: space-between;
    align-items: center; 
}

.question-support button {
    display: flex;
    text-align: left; 
    font-size: 16px;
    font-weight: 500;
    line-height: 20.42px;
}

.question-support button::after {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url('../../img/icon/uiw_down.svg');
    background-repeat: no-repeat;
    position: relative;
    padding: auto;
}

.description input {
    height: 223px; 
}

.add-file button {
    width: 764px;
    border: 5.5px dashed #DDDDDD; 
    background-color: #fff;
    height: 182px;
    padding: 20px; 
}


.add-file span {
    color: #40A6FF;
    text-decoration:  underline;
}

.submit button {
    margin-top: 48px;
    font-size: 16px;
    background: #40A6FF;
    color: #fff;
    padding: 10px;   
}