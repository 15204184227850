.specialist-card {
	border: 1px solid #c7c7c7;
	border-radius: 4px;
	padding: 16px;
	max-width: 353px;
	cursor: pointer;
	transition: 0.3s;

	&:hover{
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
	}
}



.specialist-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
	margin-bottom: 16px;
}

.specialist-name {
	font-size: 16px;
	color: #000;
	font-weight: 400;
}

.specialist-location {
	font-size: 12px;
	color: #888;
}

.specialist-details h3 {
	line-height: 1;
	margin-bottom: 12px;
}

.specialist-details p {
	font-size: 12px;
	color: #000;
	margin-bottom: 16px;
}

.avatar {
	border-radius: 20px;
	width: 32px;
	height: 32px;
}

.header-right {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.header-left {
	display: flex;
	gap: 8px;
	align-items: center;
}

.top-badge {
	border-radius: 4px;
	padding: 4px 8px;
	width: 46px;
	background-color: #7321ff;
	font-size: 16px;
	color: #f2f2f7;
	line-height: 1;
	display: block;
}

.response-time {
	font-size: 12px;
	color: #038901;
	display: block;
}

.expertise {
  display: flex;
  justify-content: space-between;
	margin-bottom: 16px;
}

.expertise-logo {
	width: 74px;
	height: 61px;
}

.stats {
  display: flex;
  justify-content: space-between;
}

.stats p {
	border: 1px solid #ddd;
	border-radius: 51px;
	padding: 16px 28px;
	width: 156px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #efefef;
	margin-bottom: 16px;
}

.hire-button {
  background-color: #40a6ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
	width: 100%;
	border-radius: 51px;
	padding: 16px 28px;
	height: 32px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
}
