.filter-section {
  margin-bottom: 1rem;
}

.filter-section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
	margin-bottom: 26px;
}

.filter-section__header h3 {
	font-weight: 700;
	font-size: 16px;
}

.filter-section__icon {
  transition: transform 0.3s;
}

.filter-section__icon.open {
	transform: rotate(90deg);
}

label {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
  cursor: pointer;
}

.styled-checkbox {
  appearance: none;
  cursor: pointer;
  position: relative;
	border: 1px solid #8e8e93;
	border-radius: 2px;
	width: 24px;
	height: 24px;
}

.styled-checkbox:checked {
  background-color: #000;
  border: 2px solid #000;
}

.styled-checkbox:checked::before {
  content: '✓';
  display: block;
  color: #fff;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}