.filter {
  max-width: 248px;
  border: 1px solid #e0e0e0;
  padding: 16px;
  border-radius: 24px;
}

.filter h2 {
	font-weight: 700;
	font-size: 16px;
}

.filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.filter-header button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

