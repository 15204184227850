.main {
  padding-top: 28px;
  padding-bottom: 116px;
}

.container--flex {
  display: flex;
}

.aside {
  width: 20%;
  max-width: 248px;
  margin-right: 30px;
}

.section {
  width: 80%;
}

.section-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 33px;
}

.title {
  font-weight: 400;
  font-size: 32px;
  color: #000;
  line-height: 100%;
  margin-bottom: 24px;
}

.tags {
  display: flex;
  gap: 10px;
  white-space: nowrap;
}

.tags__tag {
  display: inline-flex; 
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px 24px;
  cursor: pointer;
  max-height: 35px;
  background-color: transparent;
  transition: background-color 0.3s;
}

.tags__tag:hover {
  background-color: #efefef;
}
