.project-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  column-gap: 16px;
  row-gap: 56px;

  @media screen and (max-width: 900px){
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 600px){
    grid-template-columns: repeat(1, 1fr);
  }

}