/* ProjectModal.module.css */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.modalContainer {
  background: #fff;
  padding: 44px;
  border-radius: 24px;
  max-width: 1148px;
  width: 90%;
  height: auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;

  transform: scale(0.7);
  animation: scaleIn 0.3s forwards;
}

.modalCloseButton {
  position: absolute;
  top: 40px;
  right: 44px;
  background: none;
  border: none;
  cursor: pointer;
}

.modalHeader {
  margin-bottom: 20px;
}

.modalTitle {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}

.modalPublished {
  font-size: 12px;
  color: #000;
}

.modalContent {
  display: flex;
}

.modalImage {
  max-width: 698px;
  width: 100%;
  height: auto;
}

.modalDetails {
  margin-left: 20px;
}

.modalAuthor {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
}

.authorAvatar {
  width: 52px;
  height: 52px;
  margin-right: 13px;
  object-fit: cover;
}

.authorName {
  font-weight: 400;
  font-size: 24px;
  color: #000;
  margin-bottom: 12px;
  line-height: 1;
}

.subscribeButton {
  background: none;
  border: none;
  color: #000;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 0;
}

.projectStats {
  display: flex;
  gap: 8px;
}

.projectDate {
  font-size: 12px;
  color: #000;
}

.projectStats span{
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
}

.modalActions {
  margin-bottom: 125px;
}

.modalActions div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.modalActions button {
  border: none;
  cursor: pointer;
  border-radius: 51px;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.rateButton {
  background-color: #40a6ff;
  padding: 16px 49px;
  color: #fff;
}

.shareButton {
  background-color: #efefef;
  padding: 16px 28px;
}

.messageButton {
  background-color: #efefef;
  padding: 16px 49px;
  width: 100%;
}

.tags {
  display: flex;
  gap: 10px;
  white-space: nowrap;
}

.tag {
  display: inline-flex; 
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px 24px;
  cursor: pointer;
  max-height: 35px;
  background-color: transparent;
  transition: background-color 0.3s;
}

.modalDetails .tags {
  flex-wrap: wrap;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  to {
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes scaleOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.7);
  }
}

.modalOverlay.fadeOut {
  animation: fadeOut 0.2s forwards;
}

.modalContainer.scaleOut {
  animation: scaleOut 0.2s forwards;
}
