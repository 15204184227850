.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
	z-index: 100;

  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.modalContainer{
  background: #fff;
  padding: 44px 44px 0 44px;
  border-radius: 24px;
  max-width: 1148px;
  width: 90%;
  max-height: 710px;
	overflow-x: auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;

  transform: scale(0.7);
  animation: scaleIn 0.3s forwards;
}

.close{
  position: absolute;
  top: 40px;
  right: 44px;
  background: none;
  border: none;
  cursor: pointer;
}

.modalHeader {
	margin-bottom: 68px;
}

.modalHeader p {
	font-size: 20px;
	text-align: center;
	color: #000;
	margin-bottom: 28px;
}

.modalTitle {
	font-weight: 700;
	font-size: 32px;
	color: #000;
	margin-bottom: 28px;
	line-height: 100%;
	text-align: center;
}

.actions {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
}

.actions button {
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	border-radius: 51px;
	padding: 16px 28px;
	cursor: pointer;
	font-size: 16px;
}

.submitBtn {
	width: 203px;
	background-color: #40a6ff;
	color: #fff;
}

.saveBtn {
	width: 131px;
	background-color: #efefef;
	color: #000;
}

.modalContent {
	display: flex;
	justify-content: space-between;
}

.overview {
	flex-basis: 696px;
}

.overview p {
	font-size: 20px;
}

.modalDetails {
	border: 1px solid #e5e5ea;
	border-radius: 24px;
	padding: 32px;
	max-width: 320px;
	width: 100%;
	height: max-content;
}

.items {
	font-size: 20px;
	color: #000;
}

.items:not(:last-child) {
	margin-bottom: 44px;
}

.items div {
	font-weight: 700;
	font-size: 20px;
	text-transform: capitalize;
	color: #000;
	margin-top: 16px;
}


@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  to {
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes scaleOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.7);
  }
}

.modalOverlay.fadeOut {
  animation: fadeOut 0.2s forwards;
}

.modalContainer.scaleOut {
  animation: scaleOut 0.2s forwards;
}
