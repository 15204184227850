.header {
	padding: 16px 0;
}

.header__logo {
	text-decoration: none;
	color: #000;
}

.header-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header__left {
	display: flex;
	align-items: center;
	column-gap: 24px;
}

.header__nav-list {
	display: flex;
	column-gap: 8px;

}

.header__nav-li {
  position: relative;
  padding: 6px 8px;
  cursor: pointer;
  transition: color 0.3s ease;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; 
    height: 1px;
    background: #000;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  &:hover:not(:first-child) {
    &::before {
      transform: scaleX(1);
    }
  }

	&:hover .dropdown {
		display: block;
	}

	&:first-child {
		position: relative;
		padding: 6px 38px 6px 8px;

		&::after {
			content: "";
			width: 16px;
			height: 16px;
			background-image: url('../../img/icon/uiw_down.svg');
			background-repeat: no-repeat;
			position: absolute;
			right: 8px;
			top: 10px;
		}
	}

}

.header__nav-li a {
	text-decoration: none;
	font-size: 16px;
	color: #000;
}

.header__actions {
	display: flex;
	column-gap: 12px;
	align-items: center;
}

.header__search {
	border: 1px solid #ddd;
	border-radius: 51px;
	padding: 16px 28px;
	background-color: #efefef;
	width: clamp(9.375rem, 0.804rem + 35.16vw, 29.375rem);
	height: 44px;
	font-size: 16px;
	color: #999;
}

.header__actions img {
	cursor: pointer;
}

.header__login {
	cursor: pointer;
	font-size: 16px;
	color: #000;
	background-color: #efefef;
	border: 1px solid #ddd;
	border-radius: 51px;
	display: flex;
	align-items: center;
	padding: 16px 28px;
	width: fit-content;
	height: 44px;
}

.header__register {
	cursor: pointer;
	border-radius: 51px;
	display: flex;
	align-items: center;
	padding: 16px 28px;
	width: fit-content;
	height: 44px;
	background-color: #40a6ff;
	color: #fff;
	font-size: 16px;
	border: none;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 16px;
	border-radius: 4px;
  list-style: none;
  margin: 0;
  width: 256px;
  z-index: 103;
}

.dropdown li:not(:last-child){
  margin-bottom: 12px;
}

.dropdown-divider {
	border: .3px solid #ebe8e8;
	margin-bottom: 12px;
	width: 100%;
}

.dropdown li a {
  color: #000;
  display: block;
}

.header__share {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #ddd;
	border-radius: 51px;
	padding: 16px 28px;
	width: auto;
	height: 44px;
	background-color: #efefef;
	font-size: 16px;
	color: #000;
	white-space: nowrap;
	cursor: pointer;
}

.header__avatar {
	border-radius: 40px;
	width: 43px;
	height: 43px;
}